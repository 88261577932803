import { useState, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Box, TextField, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import Drawer from "react-bottom-drawer";
import { useSelector } from "react-redux";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import CloseIcon from "@mui/icons-material/Close";
import DrawerContent from "../components/common/AddItem";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import LoginPromptDrawer from "../components/login/LoginPrompt";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "100dvh",
    width: "100vw",
    boxSizing: "border-box",
    padding: "2rem 1.5rem 2.5rem 1.5rem",
  },
}));

export default function Search() {
  const navigate = useNavigate();
  const { ourproductsdata, ourcart, user, admin } = useSelector(
    (state) => state
  );
  // const {} = useSelector((state) => state);
  // const { user } = useSelector((state) => state);
  // const { admin } = useSelector((state) => state);
  const [productSearch, setProductSearch] = useState("");

  function handleSearch(event) {
    setProductSearch(event.target.value);
  }

  const handleClearSearch = () => {
    setProductSearch("");
  };

  const filteredProducts = productSearch
    ? ourproductsdata?.filter((product) =>
        product.title.toLowerCase().includes(productSearch.toLowerCase())
      )
    : [];

  const classes = useStyles();

  const [isVisible, setIsVisible] = useState(false);
  const openDrawer = useCallback(() => setIsVisible(true), []);
  const closeDrawer = useCallback(() => setIsVisible(false), []);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isVisibleProduct, setIsVisibleProduct] = useState(false);
  const openProductDetails = (item) => {
    if (!user) {
      openDrawer();
    } else {
      openProductDrawer(item);
    }
  };
  const openProductDrawer = useCallback((item) => {
    setSelectedItem(item);
    setIsVisibleProduct(true);
  }, []);
  const closeProductDrawer = useCallback(() => setIsVisibleProduct(false), []);
  const returnSataus = (status) => {
    if (status === 200) {
    }
  };
  return (
    <Box className={classes.container}>
      {/* <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          py: "1rem",
          pl: "1rem",
        }}
      >
        <Box sx={{ height: "77px", width: "77px" }}>
          <img
            src={admin.logo}
            alt="logo"
            style={{ width: "100%", height: "100%" }}
          />
        </Box>
        {ourcart?.length && user ? (
          <Box
            onClick={() => navigate("/cart")}
            sx={{
              bgcolor: "#000",
              color: "#fff",
              display: "flex",
              alignItems: "center",
              gap: "1rem",
              p: "0.5rem 1rem",
              borderRadius: "8px",
            }}
          >
            <ShoppingCartIcon sx={{ fontSize: "2.5rem" }} />
            <Typography sx={{ fontSize: "1.6rem", fontWeight: "400" }}>
              {ourcart?.length}
            </Typography>
          </Box>
        ) : (
          ""
        )}
      </Box> */}
      <Box
        sx={{
          bgcolor: "#F2F3F8",
          width: "100%",
          height: "50px",
          borderRadius: "8px",
          display: "flex",
          alignItems: "center",
          boxSizing: "border-box",
          px: "2rem",
        }}
      >
        <Link to={-1}>
          <ArrowBackIosIcon sx={{ fontSize: "3rem", color: "#92929C" }} />
        </Link>
        <TextField
          variant="outlined"
          placeholder="Search for item"
          sx={{
            "& fieldset": { border: "none" },
            width: "100%",
          }}
          inputProps={{ style: { fontSize: "1.7rem" } }}
          value={productSearch}
          onChange={handleSearch}
        />
        <CloseIcon
          sx={{ fontSize: "2rem", color: "#92929B" }}
          onClick={handleClearSearch}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "75vh",
          overflowY: "scroll",
          mt: 2,
        }}
      >
        {filteredProducts.length > 0 ? (
          filteredProducts.map((product) => (
            <Box
              key={product?._id}
              sx={{
                mt: 2,
                display: "flex",
                gap: "1rem",
                boxSizing: "border-box",
              }}
              onClick={() => openProductDetails(product)}
            >
              <Box
                sx={{
                  width: "60px",
                  height: "60px",
                  borderRadius: "50%",
                  bgcolor: "beige",
                }}
              >
                <img
                  src={product.varients[0].varientId.image}
                  alt="product"
                  style={{
                    objectFit: "cover",
                    width: "60px",
                    height: "60px",
                    borderRadius: "50%",
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Typography
                  key={product._id}
                  sx={{ fontSize: "2rem", fontWeight: "400" }}
                >
                  {/* {product.title} */}
                  {product.title
                    .toLowerCase()
                    .replace(/\b\w/g, (c) => c.toUpperCase())}
                </Typography>
                <Typography
                  sx={{ fontSize: "1.4rem", fontWeight: "400", color: "blue" }}
                >
                  In{" "}
                  {product.category?.category
                    .toLowerCase()
                    .replace(/\b\w/g, (c) => c.toUpperCase())}
                  {/* {product.category?.category} */}
                </Typography>
              </Box>
              <ArrowOutwardIcon
                sx={{
                  fontSize: "3rem",
                  marginLeft: "auto",
                  alignSelf: "center",
                }}
              />
            </Box>
          ))
        ) : (
          <Typography sx={{ mt: 2, fontSize: "1.5rem" }}>
            No products found
          </Typography>
        )}
      </Box>
      <Drawer
        duration={250}
        hideScrollbars={true}
        onClose={closeProductDrawer}
        isVisible={isVisibleProduct}
      >
        <DrawerContent
          closeDrawer={closeProductDrawer}
          selectedItem={selectedItem}
        />
      </Drawer>
      <Drawer
        duration={250}
        hideScrollbars={true}
        onClose={closeDrawer}
        isVisible={isVisible}
      >
        <LoginPromptDrawer
          returnSataus={returnSataus}
          closeprevious={closeDrawer}
        />
      </Drawer>
    </Box>
  );
}
