import { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  Divider,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import veg from "../../assets/veg.svg";
import nonVeg from "../../assets/nonVeg.svg";
import { useDispatch, useSelector } from "react-redux";
import { addToCart } from "../../store/cartslice";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export default function AddItem({ closeDrawer, selectedItem }) {
  const navigate = useNavigate();
  const { admin } = useSelector((state) => state);
  const [selectedProduct, setSelectdProduct] = useState({
    ...selectedItem,
    varients: selectedItem.varients[0],
  });
  const [selectedAddons, setSelectedAddons] = useState([]);
  const [selectdVerientValues, setSelectdVerientValues] = useState({
    value1: selectedItem.varients[0].varientId.value1,
    value2: selectedItem.varients[0].varientId.value2,
    value3: selectedItem.varients[0].varientId.value3,
    value4: selectedItem.varients[0].varientId.value4,
    value5: selectedItem.varients[0].varientId.value5,
  });
  const [count, setCount] = useState(1);
  const [totalPrice, setTotalPrice] = useState(
    selectedProduct.varients.varientId.salePrice * count
  );

  const [isBoxVisible, setIsBoxVisible] = useState(false);
  const handleWatchBtn = () => {
    setIsBoxVisible(!isBoxVisible);
  };

  const incrementCount = () => {
    setCount((prevCount) => prevCount + 1);
  };

  const decrementCount = () => {
    if (count > 1) {
      setCount((prevCount) => prevCount - 1);
    }
  };
  const findVarient = async () => {
    const { value1, value2, value3, value4, value5 } = selectdVerientValues;
    const newName = [value1, value2, value3, value4, value5]
      .filter(Boolean)
      .join(" ");
    let selectedVariant = selectedItem?.varients?.find((variant) => {
      return (
        [
          variant.varientId?.value1,
          variant.varientId?.value2,
          variant.varientId?.value3,
          variant.varientId?.value4,
          variant.varientId?.value5,
        ]
          .filter(Boolean)
          .join(" ") === newName
      );
    });
    setTotalPrice(selectedVariant.varientId.salePrice * count);
    setSelectdProduct({
      ...selectedItem,
      varients: selectedVariant,
    });
  };
  const selectAddons = (item, value) => {
    let addonIndex = selectedAddons.findIndex(
      (addon) =>
        addon.valueId === value.item._id && addon.addOnId === item.addOnId._id
    );
    if (addonIndex >= 0) {
      let Addons = selectedAddons.filter(
        (addon, index) => index !== addonIndex
      );
      setSelectedAddons(Addons);
    } else {
      setSelectedAddons([
        ...selectedAddons,
        {
          addOnId: item.addOnId._id,
          addonName: item.addOnId.title,
          name: value.item.title,
          price: value.item.price,
          valueId: value.item._id,
          quantity: 1,
        },
      ]);
    }
  };
  const calcuatePrice = () => {
    setTotalPrice(
      selectedProduct.varients.varientId.salePrice * count +
        selectedAddons.reduce((acc, cur) => {
          return acc + cur.price * cur.quantity;
        }, 0)
    );
  };
  useEffect(() => {
    findVarient();
  }, [selectdVerientValues, count]);
  useEffect(() => {
    calcuatePrice();
  }, [selectedProduct, count, selectedAddons]);
  const addItem = async () => {
    dispatch(
      addToCart({
        product: {
          _id: selectedProduct._id,
          productName: selectedProduct.title,
          vegOrNonVeg: selectedProduct.vegOrNonVeg,
        },
        varientId: selectedProduct.varients.varientId,
        quantity: count,
        addOns: selectedAddons,
      })
    );
    toast.success("Item added to cart", {
      position: "bottom-center",
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
    closeDrawer();
    navigate("/");
  };
  const dispatch = useDispatch();
  return (
    <Box
      sx={{
        minHeight: "52vh",
        maixHeight: "67vh",
        margin: "0 10px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Box>
        <Box
          sx={{
            height: "180px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              flex: 1,
              borderRadius: "20px",
            }}
          >
            <img
              src={selectedItem.varients[0].varientId.image}
              alt="biriyani"
              style={{
                objectFit: "cover",
                width: "100%",
                height: "100%",
                borderRadius: "15px",
              }}
            />
          </Box>
          <Box
            sx={{
              flex: 1,
              ml: "1rem",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
            }}
          >
            <Box sx={{ display: "flex", gap: "5px" }}>
              {selectedItem.vegOrNonVeg === "Veg" ? (
                <img src={veg} alt="nonVeg" />
              ) : (
                <img src={nonVeg} alt="nonVeg" />
              )}
            </Box>
            <Typography sx={{ fontSize: "2rem", fontWeight: "500" }}>
              {/* {selectedItem.title} */}
              {selectedItem.title
                .toLowerCase()
                .replace(/\b\w/g, (c) => c.toUpperCase())}
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <StarIcon sx={{ color: "#FE9E00", fontSize: "2rem" }} />{" "}
              <StarIcon sx={{ color: "#FE9E00", fontSize: "2rem" }} />{" "}
              <StarIcon sx={{ color: "#FE9E00", fontSize: "2rem" }} />{" "}
              <StarIcon sx={{ color: "#FE9E00", fontSize: "2rem" }} />{" "}
              <StarIcon sx={{ color: "#D1D1D1", fontSize: "2rem" }} />{" "}
              <Typography sx={{ color: "#FE9E00", fontSize: "1.5rem", px: 2 }}>
                4.0
              </Typography>
            </Box>
            {selectedItem.videoLink ? (
              <Box
                sx={{ display: "flex", alignItems: "center", gap: "0.5rem" }}
              >
                <PlayCircleOutlineIcon
                  sx={{ color: "#000", fontSize: "2.5rem", fontWeight: "400" }}
                />
                <Typography
                  sx={{ fontSize: "1.5rem", fontWeight: "400" }}
                  onClick={handleWatchBtn}
                >
                  {isBoxVisible ? "hide" : "Watch"}
                  {/* <Link to={selectedItem.videoLink} style={{ textDecoration: "none", color: "#000",  fontSize: "1.5rem", fontWeight: "400"}}></Link> */}
                </Typography>
              </Box>
            ) : (
              <Box sx={{ height: "30px" }}></Box>
            )}
          </Box>
        </Box>

        {isBoxVisible && (
          <Box sx={{ display: "flex", justifyContent: "start", mt: "2rem" }}>
            {console.log(selectedItem.videoLink, "==selectedItem.videoLink")}
            <iframe
              style={{ width: "100%", height: "200px" }}
              // src="https://www.youtube.com/embed/qmlgXeeIyXc?si=GrkQoUqW1FBwFwKg"
              src={selectedItem.videoLink}
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </Box>
        )}

        {[
          selectedItem.property1,
          selectedItem.property2,
          selectedItem.property3,
          selectedItem.property4,
          selectedItem.property5,
        ]
          .filter(Boolean)
          .map((item, index) => (
            <Box
              key={index}
              sx={{
                minHeight: "120px",
                width: "96%",
                boxSizing: "border-box",
                mx: 1,
                my: 2,
                p: 2,
                boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.1)",
                borderRadius: "10px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <Typography
                sx={{ fontSize: "2rem", fontWeight: "500", lineHeight: "30px" }}
              >
                {item}&nbsp;
                <span
                  style={{
                    fontSize: "1.5rem",
                    fontWeight: "200",
                    lineHeight: "15px",
                  }}
                >
                  (Select One)
                </span>
              </Typography>
              <Box sx={{ display: "flex", gap: "2rem" }}>
                {[
                  ...new Set(
                    selectedItem.varients
                      .map((varient) => varient.varientId[`value${index + 1}`])
                      .filter(Boolean)
                  ),
                ].map((value) => (
                  <Button
                    key={value}
                    variant="outlined"
                    sx={{
                      backgroundColor: "rgba(242, 243, 248, 1)",
                      borderColor:
                        selectdVerientValues[`value${index + 1}`] === value
                          ? "red"
                          : "rgba(242, 243, 248, 1)",
                      "&:hover": {
                        borderColor:
                          selectdVerientValues[`value${index + 1}`] === value
                            ? "red"
                            : "rgba(242, 243, 248, 1)",
                      },
                      color: "#000",
                      borderRadius: "10px",
                      width: "85px",
                      height: "35px",
                      fontSize: "1.3rem",
                      fontWeight: "400",
                    }}
                    onClick={() => {
                      setSelectdVerientValues({
                        ...selectdVerientValues,
                        [`value${index + 1}`]: value,
                      });
                    }}
                  >
                    {value}
                  </Button>
                ))}
              </Box>
            </Box>
          ))}
        {selectedItem.addOns.map((item) => (
          <Box
            key={item._id}
            sx={{
              width: "96%",
              boxSizing: "border-box",
              mx: 1,
              my: 2,
              p: 2,
              boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.1)",
              borderRadius: "10px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Typography
              sx={{ fontSize: "2rem", fontWeight: "500", lineHeight: "30px" }}
            >
              {item.addOnId.title}&nbsp;
              <span
                style={{
                  fontSize: "1.5rem",
                  fontWeight: "200",
                  lineHeight: "15px",
                }}
              >
                (Select One)
              </span>
            </Typography>
            <Box
              sx={{
                display: "flex",
                gap: "1rem",
                flexDirection: "column",
                mt: "5px",
              }}
            >
              {item?.addOnId?.items.map((value) => (
                <Button
                  key={value._id}
                  variant="text"
                  sx={{
                    color: "#000",
                    borderRadius: "10px",
                    width: "100%",
                    height: "45px",
                    fontSize: "1.3rem",
                    fontWeight: "400",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                  onClick={() => {
                    selectAddons(item, value);
                  }}
                >
                  <FormControlLabel
                    sx={{
                      "& .MuiFormControlLabel-label": { fontSize: "1.5rem" },
                    }}
                    control={
                      <Checkbox
                        checked={
                          selectedAddons.findIndex(
                            (addon) =>
                              addon.valueId === value.item._id &&
                              addon.addOnId === item.addOnId._id
                          ) >= 0
                        }
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: "2.1rem",
                            color: "red",
                          },
                        }}
                      />
                    }
                    label={value.item.title}
                  />
                  <Typography sx={{ fontSize: "1.5rem" }}>
                    {value.item.price}
                  </Typography>
                </Button>
              ))}
            </Box>
          </Box>
        ))}
      </Box>
      <Box>
        <Divider />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "1.5rem",
            my: 2,
          }}
        >
          <Box
            sx={{
              width: "30px",
              height: "30px",
              bgcolor: "#D9D9D9",
              borderRadius: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={decrementCount}
          >
            <Typography sx={{ fontSize: "3rem" }}>-</Typography>
          </Box>
          <Typography sx={{ fontSize: "2rem" }}>{count}</Typography>
          <Box
            sx={{
              width: "30px",
              height: "30px",
              bgcolor: "#D9D9D9",
              borderRadius: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={incrementCount}
          >
            <Typography sx={{ fontSize: "2rem" }}>+</Typography>
          </Box>
          <Button
            sx={{
              backgroundColor: "#FF2E2E",
              color: "#fff",
              flex: 1,
              height: "40px",
              borderRadius: "10px",
              fontSize: "1.3rem",
              fontWeight: "400",
              "&:hover": {
                backgroundColor: "#FF2E2E",
              },
            }}
            onClick={() => addItem()}
          >
            Add item - &nbsp;
            <span style={{ fontSize: "1.1rem" }}>{admin?.currencySymbol}</span>
            &nbsp;
            {totalPrice.toFixed(2)}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
