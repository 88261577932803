import React from "react";
import EngineeringIcon from "@mui/icons-material/Engineering";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import { Box, Divider, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

const PopupStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70vw",
  height: "auto",
  bgcolor: "#fff",
  borderRadius: "20px",
  boxShadow: 24,
  p: "2rem 2rem",
  margin: "auto",
  display: "flex",
  flexDirection: "column",
};

export default function CouponNotAvilable({ close }) {
  return (
    <Box>
      <Box sx={PopupStyle}>
        {/* <CardGiftcardIcon
          sx={{
            color: "#F04141",
            fontSize: "5rem",
            marginInline: "auto",
          }}
        /> */}
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <CloseIcon sx={{ color: "#878787", fontSize: "2rem", }} onClick={close} />
      </Box>
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontSize: "1.3rem",
            fontWeight: "400",
            marginInline: "auto",
            textAlign: "center",
            my: 2,
            color: "#777777",
          }}
        >
          <span style={{ fontFamily: "Poppins", fontSize: "1.7rem" }}>
            Sorry,
          </span>
          <br /> There is no coupon avilable
        </Typography>
        {/* <Typography
          sx={{
            fontSize: "1.4rem",
            fontWeight: "400",
            color: "#AFAFAF",
            marginInline: "auto",
            textAlign: "center",
          }}
        >
          will be live soon :)
        </Typography> */}
        <Divider />
        <Typography
          onClick={close}
          sx={{
            color: "#F04141",
            marginInline: "auto",
            mt: 2,
            fontSize: "1.6rem",
            fontWeight: "500",
          }}
        >
          OK
        </Typography>
      </Box>
    </Box>
  );
}
