import { useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Button,
  Divider,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import veg from "../assets/veg.svg";
import nonVeg from "../assets/nonVeg.svg";
import StarIcon from "@mui/icons-material/Star";
import FavoriteIcon from "@mui/icons-material/Favorite";
import Drawer from "react-bottom-drawer";
import DrawerContent from "../components/common/AddItem";
import { useDispatch, useSelector } from "react-redux";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import LoginPromptDrawer from "../components/login/LoginPrompt";
import { useEffect } from "react";
import { useCallback } from "react";
import MenuModal from "../components/menu/MenuModal";
import { toast } from "react-toastify";
import {
  addToFavourites,
  removeItemFromFavourites,
} from "../store/favouriteslice";
import RemoveItem from "../components/common/RemoveItem";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "100dvh",
    width: "100dvw",
    boxSizing: "border-box",
    padding: "2.5rem 1.5rem 2.5rem 1.5rem",
    display: "flex",
    flexDirection: "column",
  },
}));

export default function Menu() {
  const navigate = useNavigate();
  const { categoryId } = useParams();
  const { user } = useSelector((state) => state);
  const { ourfavourites } = useSelector((state) => state);
  const { ourproductsdata } = useSelector((state) => state);
  const { admin } = useSelector((state) => state);
  const [filter, setFilter] = useState("");
  const [filterdProduct, setFilterdProduct] = useState([]);
  const dispatch = useDispatch();

  const [items, setItems] = useState([]);
  const fetchItems = async () => {
    const productsWithTargetId = ourproductsdata.filter((product) => {
      return product.groups.some((group) => group.group._id === categoryId);
    });
    setItems(productsWithTargetId);
    setFilterdProduct(productsWithTargetId);
  };

  useEffect(() => {
    fetchItems();
  }, [categoryId]);

  const addtoFav = (id, e) => {
    e.stopPropagation();
    // if (!user) {
    //   openLoginDrawer();
    // } else {
    handleaddToFavourites(id);
    // }
  };
  const addtoCart = (item) => {
    // if (!user) {
    //   openLoginDrawer();
    // } else {
    openDrawer(item);
    // }
  };

  const [favProducts, setFavProducts] = useState([]);

  const findFavProducts = async () => {
    // if (user) {
    setFavProducts(ourfavourites);
    // }
  };

  useEffect(() => {
    findFavProducts();
  }, [ourfavourites, user]);

  const [selectedItem, setSelectedItem] = useState(null);

  const [isVisible, setIsVisible] = useState(false);
  const openDrawer = useCallback((item) => {
    setSelectedItem(item);
    setIsVisible(true);
  }, []);
  const closeDrawer = useCallback(() => setIsVisible(false), []);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [isVisibleLogin, setIsVisibleLogin] = useState(false);
  const openLoginDrawer = useCallback(() => setIsVisibleLogin(true), []);
  const closeLoginDrawer = useCallback(() => {
    setIsVisibleLogin(false);
  }, []);

  const returnSataus = (status) => {
    if (status === 200) {
    }
  };

  const classes = useStyles();

  const filterVegAndNonVeg = (value) => {
    const data = items.filter((item) => item.vegOrNonVeg === value);
    setFilterdProduct(data);
  };
  const filterBestSeller = () => {
    const data = items.filter((item) => item.bestSeller);
    setFilterdProduct(data);
  };

  const handleaddToFavourites = (id) => {
    dispatch(addToFavourites(id));
    toast.success("Item added to favourite", {
      position: "bottom-center",
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };

  const [deleteFavProductId, setDeleteFavProductId] = useState(null);
  const [removeItemModalOpen, setRemoveItemModalOpen] = useState(false);
  const removeItemModalhandleOpen = (e) => {
    e.stopPropagation();
    setRemoveItemModalOpen(true);
  };
  const removeItemModalhandleClose = () => setRemoveItemModalOpen(false);

  const handleRemoveFromFavourite = () => {
    dispatch(removeItemFromFavourites(deleteFavProductId));
  };
  return (
    <Box className={classes.container}>
      <Box
        sx={{
          bgcolor: "#E9E9E9",
          width: "100%",
          height: "50px",
          borderRadius: "8px",
          display: "flex",
          alignItems: "center",
          boxSizing: "border-box",
          px: "2rem",
        }}
      >
        <Link to="/">
          <ArrowBackIosIcon sx={{ fontSize: "2.3rem", color: "#000" }} />
        </Link>
        <TextField
          variant="outlined"
          placeholder="Search in Menu"
          sx={{
            "& fieldset": { border: "none" },
            width: "100%",
          }}
          onClick={() => navigate("/search")}
          inputProps={{ style: { fontSize: "1.7rem" } }}
        />
      </Box>
      <Box sx={{ display: "flex", gap: "8px", py: 2 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            p: "3px",
            border:
              filter === "veg" ? "1px solid #FF2E2E" : "1px solid #9F9F9F",
            borderRadius: "5px",
          }}
          onClick={() => {
            filterVegAndNonVeg("Veg");
            setFilter("veg");
          }}
        >
          <img src={veg} alt="veg" />
          <Typography sx={{ fontSize: "1.6rem", px: 1 }}>Veg</Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            p: "3px",
            border:
              filter === "NonVeg" ? "1px solid #FF2E2E" : "1px solid #9F9F9F",
            borderRadius: "5px",
          }}
          onClick={() => {
            filterVegAndNonVeg("NonVeg");
            setFilter("NonVeg");
          }}
        >
          <img src={nonVeg} alt="nonVeg" />
          <Typography sx={{ fontSize: "1.6rem", px: 1 }}>Non-veg</Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            p: "3px",
            border:
              filter === "bestSeller"
                ? "1px solid #FF2E2E"
                : "1px solid #9F9F9F",
            borderRadius: "5px",
          }}
          onClick={() => {
            filterBestSeller();
            setFilter("bestSeller");
          }}
        >
          <Typography sx={{ fontSize: "1.6rem", px: 1 }}>Bestseller</Typography>
        </Box>
      </Box>
      {filterdProduct?.length === 0 ? (
        ""
      ) : (
        <Typography sx={{ fontSize: "2rem", fontWeight: "500", pb: 2 }}>
          Recommended
        </Typography>
      )}
      <Box sx={{ height: "auto", overflowY: "scroll" }}>
        {filterdProduct.length === 0 ? (
          <Typography
            sx={{ fontSize: "2rem", display: "flex", justifyContent: "center" }}
          >
            No products found.
          </Typography>
        ) : (
          filterdProduct?.map((item) => (
            <Box sx={{ display: "flex", flexDirection: "column", }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
                key={item._id}
              >
                <Box>
                  <Box sx={{ display: "flex", gap: "5px" }}>
                    {item.vegOrNonVeg === "Veg" ? (
                      <img src={veg} alt="nonVeg" />
                    ) : (
                      <img src={nonVeg} alt="nonVeg" />
                    )}
                    {item?.bestSeller && (
                      <Box
                        sx={{
                          bgcolor: "orange",
                          color: "white",
                          px: 1,
                          borderRadius: "3px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Typography>Bestseller</Typography>
                      </Box>
                    )}
                  </Box>
                  <Typography sx={{ fontSize: "2rem", fontWeight: "500" }}>
                    {item.title
                      .toLowerCase()
                      .replace(/\b\w/g, (c) => c.toUpperCase())}
                  </Typography>
                  <Typography sx={{ fontSize: "2rem", fontWeight: "500" }}>
                    <span style={{ fontSize: "1.5rem", fontWeight: "500" }}>
                      {admin?.currencySymbol}{" "}
                    </span>
                    {Math.max
                      .apply(
                        null,
                        item?.varients?.map(
                          (item) => item?.varientId?.salePrice
                        )
                      )
                      .toFixed(2)}
                  </Typography>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <StarIcon sx={{ color: "#FE9E00", fontSize: "2rem" }} />{" "}
                    <StarIcon sx={{ color: "#FE9E00", fontSize: "2rem" }} />{" "}
                    <StarIcon sx={{ color: "#FE9E00", fontSize: "2rem" }} />{" "}
                    <StarIcon sx={{ color: "#FE9E00", fontSize: "2rem" }} />{" "}
                    <StarIcon sx={{ color: "#D1D1D1", fontSize: "2rem" }} />{" "}
                    <Typography
                      sx={{ color: "#FE9E00", fontSize: "1.5rem", px: 1 }}
                    >
                      4.0
                    </Typography>
                  </Box>
                  <Typography
                    sx={{ color: "#9D9D9D", fontSize: "1.6rem" }}
                    onClick={() => addtoCart(item)}
                  >
                    View More
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: "125px",
                    height: "160px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    position: "relative",
                  }}
                  onClick={() => addtoCart(item)}
                >
                  <FavoriteIcon
                    sx={{
                      color: favProducts?.includes(item?._id)
                        ? "#FF2E2E"
                        : "#D1D1D1",
                      fontSize: "3rem",
                      position: "absolute",
                      top: 10,
                      right: 10,
                    }}
                    onClick={(event) => {
                      let favItem = favProducts?.includes(item?._id);

                      if (favItem) {
                        setDeleteFavProductId(item?._id);
                        removeItemModalhandleOpen(event);
                      } else {
                        addtoFav(item?._id, event);
                      }
                    }}
                  />
                  <img
                    src={item.varients[0]?.varientId?.image}
                    alt="biriyani"
                    style={{
                      objectFit: "cover",
                      width: "125px",
                      height: "125px",
                      borderRadius: "5px",
                    }}
                  />
                  <Button
                    sx={{
                      backgroundColor: "#FF2E2E",
                      color: "#fff",
                      width: "90px",
                      fontSize: "1.1rem",
                      borderRadius: "10px",
                      position: "absolute",
                      bottom: 20,
                      "&:hover": {
                        backgroundColor: "#FF2E2E",
                      },
                    }}
                    className="open-btn"
                    onClick={() => addtoCart(item)}
                  >
                    ADD
                  </Button>
                </Box>
              </Box>
              <Divider sx={{ mb: 2.5 }} />
            </Box>
          ))
        )}
      </Box>
      <Box
        sx={{
          bgcolor: "#000",
          position: "fixed",
          p: 2,
          borderRadius: "15px",
          display: "flex",
          alignItems: "center",
          gap: "20px",
          bottom: "20px",
          left: "50%",
          transform: "translateX(-50%)",
          zIndex: "1",
        }}
        onClick={handleOpen}
      >
        <AutoStoriesIcon sx={{ color: "#fff", fontSize: "3rem" }} />
        <Typography sx={{ fontSize: "2rem", color: "#fff" }}>Menu</Typography>
      </Box>

      <Box>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <MenuModal handleClose={handleClose} />
        </Modal>
      </Box>
      <Drawer
        duration={250}
        hideScrollbars={true}
        onClose={closeDrawer}
        isVisible={isVisible}
      >
        <DrawerContent closeDrawer={closeDrawer} selectedItem={selectedItem} />
      </Drawer>
      <Drawer
        duration={250}
        hideScrollbars={true}
        onClose={closeLoginDrawer}
        isVisible={isVisibleLogin}
      >
        <LoginPromptDrawer
          returnSataus={returnSataus}
          closeprevious={closeLoginDrawer}
        />
      </Drawer>
      <Box>
        <Modal
          open={removeItemModalOpen}
          onClose={removeItemModalhandleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <RemoveItem
            removeItemModalhandleClose={removeItemModalhandleClose}
            handleDeleteFavProduct={handleRemoveFromFavourite}
            head1={"product"}
          />
        </Modal>
      </Box>
    </Box>
  );
}
