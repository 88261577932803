import { Box } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from "react-router-dom";

export default function TopBar() {
  const navigate = useNavigate();

  function back() {
    navigate(-1);
  }
  return (
    <Box
      sx={{
        width: "100%",
        height: "50px",
        borderRadius: "8px",
        display: "flex",
        alignItems: "center",
        boxSizing: "border-box",
        px: "2rem",
      }}
    >
      <ArrowBackIcon
        sx={{ fontSize: "3rem", color: "#383B41" }}
        onClick={back}
      />
    </Box>
  );
}
