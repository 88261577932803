import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";

export default function CategoryCard({ category }) {
  return (
    <Link
      to={`/menu/${category.id}`}
      key={category.id}
      style={{ textDecoration: "none", color: "#000" }}
    >
      <Box
        key={category.id}
        sx={{
          width: "115px",
          maxHeight: "150px",
          minHeight: "130px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "1rem",
          flexShrink: 0,
          boxSizing: "border-box",
          textAlign: "center",
        }}
      >
        <img
          src={category.image}
          style={{
            objectFit: "cover",
            width: "90px",
            minHeight: "90px",
            maxHeight: "90px",
            borderRadius: "10px",
          }}
          alt="category"
        />
        <Typography
          sx={{
            fontSize: "1.3rem",
            fontWeight: "500",
            fontFamily: 'Montserrat',
          }}
        >
          {category.groupName
            .toLowerCase()
            .replace(/\b\w/g, (c) => c.toUpperCase())}
        </Typography>
      </Box>
    </Link>
  );
}
