import { Box, Typography } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useSelector } from "react-redux";

export default function ProfileDetails() {
  const { user } = useSelector((state) => state);
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        background: "#FFFFFF",
        px: "2rem",
        py: "1rem",
        alignItems: "center",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
        <Typography sx={{ fontSize: "1.6rem", fontWeight: "500" }}>{user.username}</Typography>
      </Box>
      <AccountCircleIcon sx={{ fontSize: "6rem", color: "#92929B" }} />
    </Box>
  );
}
