import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Button,
  Typography,
  FormControlLabel,
  Radio,
  RadioGroup,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  Modal,
  CircularProgress,
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import {
  Link,
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import Drawer from "react-bottom-drawer";
import veg from "../assets/veg.svg";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import WalletIcon from "@mui/icons-material/Wallet";
import ShortTextIcon from "@mui/icons-material/ShortText";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import RedeemIcon from "@mui/icons-material/Redeem";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useDispatch, useSelector } from "react-redux";
import { getAllAddress } from "../sevice/address";
import Cookies from "js-cookie";
import { placeOrder, userOrderDetails } from "../sevice/order";
import MinOrderValueModal from "../components/cart/MinOrderValueModal";
import NotDesiredLocationModal from "../components/cart/NotDesiredLocationModal";
import OrderPlacedSuccessModal from "../components/cart/OrderPlacedSuccessModal";
import AddressModal from "../components/cart/AddressModal";
import {
  addToCart,
  clearCart,
  decreaseCart,
  removeFromCart,
} from "../store/cartslice";
import PickupModal from "../components/cart/PickupModal";
import { handlePayment, paymentHandler } from "../sevice/razorpay";
import RemoveItem from "../components/common/RemoveItem";
import Service from "../components/common/Service";
import CouponNotAvilable from "../components/common/CouponNotAvilable";
import { useCallback } from "react";
import LoginPromptDrawer from "../components/login/LoginPrompt";
import Lottie from "lottie-react";
import deliveryBoy from "../lottie/delivery-boy.json";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "100dvh",
    width: "100vw",
    boxSizing: "border-box",
    padding: "0rem 0rem 2.5rem 0rem",
    backgroundColor: "rgba(248, 248, 248, 1)",
    position: "relative",
  },
}));

export default function Cart() {
  const { ourcart, ourInstruction, user, address, admin, someonecontact } =
    useSelector((state) => state);
  const boxRef = useRef(null);
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [typeofOrder, setTypeofOrder] = useState("Delivery");
  const [isLoading, setisLoading] = useState(false);
  const [subTotal, setSubTotal] = useState(0);
  const [deliveryCharge, setDeliveryCharge] = useState(0);
  const [orderItems, setOrderItems] = useState([]);
  const [gst, setGst] = useState(0);
  const [selectedMethod, setSelectedMethod] = useState("COD");
  // const [isPlace, setIsPlace] = useState(false);

  const [isVisibleLogin, setIsVisibleLogin] = useState(false);
  const openDrawerLogin = useCallback(() => setIsVisibleLogin(true), []);
  const closeDrawerLogin = useCallback(() => setIsVisibleLogin(false), []);
  const returnSataus = (status) => {
    if (status === 200) {
      // setIsPlace(true);
      boxRef.current.scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  // useEffect(() => {
  //   if (isPlace) {
  //     setIsPlace(false);
  //     placeOrderFunction();
  //   }
  // }, [address]);

  const handleChange = (event) => {
    setSelectedMethod(event.target.value);
  };

  const [orderModalOpen, setOrderModalOpen] = useState(false);
  const orderModalHandleOpen = () => setOrderModalOpen(true);
  const orderModalHandleClose = () => setOrderModalOpen(false);

  useEffect(() => {
    setSubTotal(
      ourcart?.reduce((acc, cur) => {
        return (
          acc +
          cur.varientId.salePrice * cur.quantity +
          cur?.addOns?.reduce((acc2, cur2) => {
            return acc2 + cur2.price * cur2.quantity;
          }, 0)
        );
      }, 0)
    );
  }, [ourcart]);

  useEffect(() => {
    findDeliveryCharge();
    findGst();
  }, [subTotal, address, typeofOrder]);

  useEffect(() => {
    findAddress();
  }, [user]);

  console.log(admin);

  const findDeliveryCharge = () => {
    let deliveryCharge = 0;
    if (typeofOrder === "Pickup" || address?.distance > admin.deliveryRadius) {
      deliveryCharge = 0;
    } else if (subTotal >= admin?.freeDeliveryValue) {
      deliveryCharge = 0;
    } else if (address?.distance < address?.freeDeliveryKm) {
      deliveryCharge = 0;
    }
    // else if (address?.distance > admin.deliveryRadius) {
    //   deliveryCharge = 0;
    // }
    else {
      address?.distance
        ? (deliveryCharge =
            parseInt(address?.distance) * admin?.deliveryChargePerKm
              ? Number(admin?.deliveryChargePerKm)
              : 0)
        : (deliveryCharge = 0);
    }
    setDeliveryCharge(deliveryCharge);
  };
  const findGst = () => {
    setGst((subTotal * admin.gstPercentage) / 100);
  };

  const findAddress = async () => {
    if (!address && user) {
      const res = await getAllAddress(user._id);
      if (res.length) {
        dispatch({ type: "ADDRESS", payload: res[0] });
        Cookies.set("address", JSON.stringify(res[0]));
      }
    }
  };

  const placeOrderFunction = async () => {
    // const is = !orderItems.some((item) => {
    //   return (
    //     item.deliveryDetails.locality === address?.address,
    //     item.typeofOrder === typeofOrder
    //   );
    // });
    if (!address && typeofOrder !== "Pickup") {
      addrModalhandleOpen();
    } else if (
      address?.distance > admin.deliveryRadius &&
      typeofOrder !== "Pickup"
    ) {
      locModalHandleOpen();
    } else if (
      admin.minOrderValue > subTotal &&
      orderItems.some((item) => {
        return (
          item.deliveryDetails.locality === address?.address,
          item.typeofOrder === typeofOrder
        );
      }) === false
    ) {
      handleOpen();
    } else {
      const orderProducts = ourcart.map((item) => {
        return {
          productName: item.product.productName,
          productId: item.product._id,
          varientId: item.varientId._id,
          salePrice: item.varientId.salePrice,
          quantity: item.quantity,
          addOns: item.addOns,
          subTotal:
            item.varientId.salePrice * item.quantity +
            item.addOns.reduce((acc, cur) => {
              return acc + cur.price * cur.quantity;
            }, 0),
        };
      });
      let orderData = {
        userId: user._id,
        paymentMethod: selectedMethod,
        // paymentId: "",
        // tableNo: "0",
        grandTotal: (subTotal + deliveryCharge + gst).toFixed(2),
        isOrderForOthers: someonecontact ? true : false,
        note: ourInstruction,
        typeofOrder: typeofOrder,
        gstPercentage: admin.gstPercentage,
        productsSubTotal: subTotal,
        gstAmount: gst,
        currentTime: new Date(),
        deliveryCharge: deliveryCharge,
        products: orderProducts,
        vendorId: admin._id,
        deliveryDetails: {
          name: someonecontact ? someonecontact.name : user.username,
          mobile: someonecontact ? someonecontact.number : user.phone,
          addressId: typeofOrder === "Delivery" ? address?._id : null,
          locality: typeofOrder === "Delivery" ? address?.address : "",
        },
      };
      if (orderData.paymentMethod === "UPI") {
        <Navigate to="/dashboard" state={{ todos: [] }} replace={true} />;
        console.log(orderData);
        const resPayment = await handlePayment(
          orderData,
          orderModalHandleOpen,
          setisLoading
        );
        console.log(resPayment);
        // if (resPayment?.status) {
        //   const res = await placeOrder(orderData);
        //   if (res?.status === 200) {
        //     orderModalHandleOpen();
        //     setTimeout(() => {
        //       dispatch(clearCart());
        //       navigate("/");
        //     }, 2000);
        //   }
        // }
      } else {
        setisLoading(true);
        const res = await placeOrder(orderData);

        if (res.status === 200) {
          orderModalHandleOpen();
          setTimeout(() => {
            dispatch(clearCart());
            navigate("/");
          }, 2000);
        }
      }
    }
  };
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  if (ourcart?.length < 1) {
    navigate("/");
  }

  const [addrModalOpen, setAddrModalOpen] = useState(false);
  const addrModalhandleOpen = () => setAddrModalOpen(true);
  const addrModalhandleClose = () => {
    setAddrModalOpen(false);
    boxRef.current.scrollIntoView({
      behavior: "smooth",
    });
  };

  const [pickupModalOpen, setPickupModalOpen] = useState(false);
  const pickupModalhandleOpen = () => setPickupModalOpen(true);
  const pickupModalhandleClose = () => setPickupModalOpen(false);

  const [locModalOpen, setLocModalOpen] = useState(false);
  const locModalHandleOpen = () => setLocModalOpen(true);
  const locModalHandleClose = () => setLocModalOpen(false);

  const [couponNotAvilableModalOpen, setCouponNotAvilableModalOpen] =
    useState(false);
  const couponNotAvilableModalHandleOpen = () =>
    setCouponNotAvilableModalOpen(true);
  const couponNotAvilableModalHandleClose = () =>
    setCouponNotAvilableModalOpen(false);
  // const [serviceModalOpen, setServiceModalOpen] = useState(false);
  // const serviceModalHandleOpen = () => setServiceModalOpen(true);
  // const serviceModalHandleClose = () => setServiceModalOpen(false);

  const [deleteProductId, setFavProductId] = useState(null);
  const [removeItemModalOpen, setRemoveItemModalOpen] = useState(false);
  const removeItemModalhandleOpen = () => setRemoveItemModalOpen(true);
  const removeItemModalhandleClose = () => setRemoveItemModalOpen(false);
  const location = useLocation();

  const previousRoute = location?.state;
  useEffect(() => {
    if (previousRoute !== "/giftinginfo") {
      dispatch({ type: "SOMEONECONTACTDATA", payload: null });
    }
  }, []);
  const handleDecreaseCart = (product) => {
    dispatch(decreaseCart(product));
  };
  const handleAddToCart = (product) => {
    dispatch(addToCart(product));
  };
  const handleRemoveFromCart = () => {
    console.log(deleteProductId);
    dispatch(removeFromCart(deleteProductId));
  };

  useEffect(() => {
    finduserOrderDetails();
  }, [user]);
  const finduserOrderDetails = async () => {
    if (user) {
      const res = await userOrderDetails(user?._id);
      setOrderItems(res);
    }
  };

  return (
    <Box className={classes.container}>
      <Box
        sx={{
          height: "65px",
          // height: "8%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
        }}
      >
        <Link to="/">
          <ArrowBackIosIcon
            sx={{
              fontSize: "3rem",
              position: "absolute",
              left: 20,
              top: 20,
              ontSize: "3rem",
              color: "#303142",
            }}
          />
        </Link>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography sx={{ fontSize: "2rem", fontWeight: "500" }}>
            Cart
          </Typography>
          <Typography
            sx={{
              fontSize: "1.3rem",
              fontWeight: "400",
              color: "rgba(173, 173, 173, 1)",
            }}
          >
            {ourcart.length ? ourcart.length : ""} Item
          </Typography>
        </Box>
      </Box>
      {!ourcart.length ? (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Box
            sx={{ width: "277px", height: "277px", alignSelf: "center", mt: 2 }}
          >
            <Lottie animationData={deliveryBoy} />
          </Box>
          <Typography
            sx={{
              fontSize: "2rem",
              fontWeight: "400",
              alignSelf: "center",
              mt: 2,
            }}
          >
            No Cart Items
          </Typography>
          <Box sx={{ alignSelf: "center" }}>
            <Button
              sx={{
                backgroundColor: "#FF2E2E",
                color: "#fff",
                width: "200px",
                height: "40px",
                fontSize: "1.5rem",
                fontWeight: "400",
                borderRadius: "10px",
                alignSelf: "center",
                mt: 2,
                "&:hover": {
                  backgroundColor: "#FF2E2E",
                },
              }}
              onClick={() => navigate("/")}
            >
              Continue Shopping
            </Button>
          </Box>
        </Box>
      ) : (
        <>
          <Box sx={{ overflowY: "scroll", height: "82%" }}>
            <Box ref={boxRef}></Box>
            {(typeofOrder !== "Pickup" && user) ||
            (typeofOrder !== "Pickup" && someonecontact) ? (
              <Box
                sx={{
                  bgcolor: "#fff",
                  height: "80px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  boxSizing: "border-box",
                  p: "1rem 2rem",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "1.4rem",
                    fontWeight: "400",
                    maxWidth: "80%",
                  }}
                >
                  Deliver to:{" "}
                  {address?.address ? (
                    <span style={{ fontWeight: "600" }}>
                      {someonecontact ? someonecontact?.name : user?.username}
                    </span>
                  ) : (
                    "Not selected"
                  )}
                  <Typography
                    sx={{
                      fontSize: "1.4rem",
                      fontWeight: "400",
                      maxWidth: "80%",
                    }}
                  ></Typography>
                  {address?.address}
                </Typography>
                <Typography
                  sx={{
                    color: "rgba(255, 46, 46, 1)",
                    fontSize: "1.3rem",
                    fontWeight: "500",
                  }}
                  onClick={() => {
                    user ? navigate("/address") : openDrawerLogin();
                  }}
                >
                  Change
                </Typography>
              </Box>
            ) : (
              <Divider />
            )}
            <Box
              sx={{
                height: "80px",
                display: "flex",
                gap: "10px",
                alignItems: "center",
                boxSizing: "border-box",
                p: "1rem 2rem",
              }}
            >
              <Button
                sx={{
                  color:
                    typeofOrder === "Delivery"
                      ? "rgba(255, 46, 46, 1)"
                      : "black",
                  width: "90px",
                  height: "30px",
                  fontSize: "1.1rem",
                  borderRadius: "5px",
                  "&:hover": {
                    borderColor:
                      typeofOrder === "Delivery"
                        ? "rgba(255, 46, 46, 1)"
                        : "black",
                  },
                  borderColor:
                    typeofOrder === "Delivery"
                      ? "rgba(255, 46, 46, 1)"
                      : "black",
                }}
                variant="outlined"
                onClick={() => setTypeofOrder("Delivery")}
              >
                Delivery
              </Button>
              <Button
                sx={{
                  color:
                    typeofOrder === "Pickup" ? "rgba(255, 46, 46, 1)" : "black",
                  borderColor:
                    typeofOrder === "Pickup" ? "rgba(255, 46, 46, 1)" : "black",
                  width: "90px",
                  height: "30px",
                  fontSize: "1.1rem",
                  borderRadius: "5px",
                  "&:hover": {
                    borderColor:
                      typeofOrder === "Pickup"
                        ? "rgba(255, 46, 46, 1)"
                        : "black",
                  },
                }}
                variant="outlined"
                onClick={() => {
                  typeofOrder !== "Pickup" && pickupModalhandleOpen();
                }}
              >
                Pickup
              </Button>
            </Box>
            <Box
              sx={{
                bgcolor: "#fff",
                height: "auto",
                display: "flex",
                flexDirection: "column",
                boxSizing: "border-box",
                p: "1rem 2rem",
              }}
            >
              {ourcart.map((item, index) => (
                <Box
                  key={item?._id}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    mt: "5px",
                  }}
                >
                  <Box sx={{ display: "flex", gap: "10px" }}>
                    <img
                      src={item.varientId.image}
                      alt="veg"
                      style={{
                        width: "60px",
                        height: "60px",
                        objectFit: "cover",
                        borderRadius: "5px",
                      }}
                    />
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        mt: "8px",
                      }}
                    >
                      <Typography
                        sx={{ fontSize: "1.5rem", fontWeight: "400" }}
                      >
                        {/* {item.varientId.productName} */}
                        {item.varientId.productName
                          .toLowerCase()
                          .replace(/\b\w/g, (c) => c.toUpperCase())}
                      </Typography>
                      {item.addOns.length ? (
                        <Box sx={{ display: "flex", gap: "2px" }}>
                          <Typography
                            sx={{ fontSize: "1.4rem", fontWeight: "400" }}
                          >
                            <span style={{ color: "red" }}>ADDON:</span>
                          </Typography>
                          <Box>
                            {item.addOns.map((addon) => (
                              <Typography
                                key={addon?._id}
                                sx={{ fontSize: "1.4rem", fontWeight: "400" }}
                              >
                                {/* {addon.name} */}
                                {addon.name
                                  .toLowerCase()
                                  .replace(/\b\w/g, (c) => c.toUpperCase())}
                              </Typography>
                            ))}
                          </Box>
                        </Box>
                      ) : (
                        ""
                      )}
                    </Box>
                  </Box>
                  <Box sx={{ display: "flex", gap: "10px", mt: "3px" }}>
                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                      <Box
                        sx={{
                          border: "1px solid red",
                          bgcolor: "#FBE7E7",
                          display: "flex",
                          gap: "10px",
                          alignItems: "center",
                          justifyContent: "space-between",
                          width: "80px",
                          height: "30px",
                          borderRadius: "10px",
                          px: 0.5,
                        }}
                      >
                        <Typography
                          sx={{
                            color: "rgba(244, 0, 0, 1)",
                            fontSize: "3rem",
                            px: 1,
                          }}
                          onClick={() => {
                            if (item.quantity > 1) {
                              handleDecreaseCart(item);
                            } else {
                              setFavProductId(item);
                              removeItemModalhandleOpen();
                            }
                          }}
                        >
                          -
                        </Typography>
                        <Typography
                          sx={{ fontSize: "1.5rem", fontWeight: "500" }}
                        >
                          {item.quantity}
                        </Typography>
                        <Typography
                          sx={{
                            color: "rgba(244, 0, 0, 1)",
                            fontSize: "2rem",
                            px: 1,
                          }}
                          onClick={() => handleAddToCart(item)}
                        >
                          +
                        </Typography>
                      </Box>
                      {admin?.currencySymbol === "AED" ? (
                        <Typography
                          sx={{
                            fontSize: "1.5rem",
                            fontWeight: "400",
                            textAlign: "end",
                            mt: "5px",
                          }}
                        >
                          {(
                            item.varientId.salePrice * item.quantity +
                            item.addOns.reduce((acc, cur) => {
                              return acc + cur.price * cur.quantity;
                            }, 0)
                          ).toFixed(2)}
                          <span style={{ fontSize: "1.2rem" }}>
                            &nbsp;{admin?.currencySymbol}
                          </span>
                        </Typography>
                      ) : (
                        <Typography
                          sx={{
                            fontSize: "1.5rem",
                            fontWeight: "400",
                            textAlign: "end",
                            mt: "5px",
                          }}
                        >
                          <span style={{ fontSize: "1.2rem" }}>
                            {admin?.currencySymbol}&nbsp;
                          </span>
                          {(
                            item.varientId.salePrice * item.quantity +
                            item.addOns.reduce((acc, cur) => {
                              return acc + cur.price * cur.quantity;
                            }, 0)
                          ).toFixed(2)}
                        </Typography>
                      )}
                    </Box>
                    {/* <DeleteForeverIcon
                      sx={{
                        color: "red",
                        fontSize: "3rem",
                        alignSelf: "flex-start",
                      }}
                      onClick={() => {
                        setFavProductId(item);
                        removeItemModalhandleOpen();
                      }}
                    /> */}
                  </Box>
                </Box>
              ))}
            </Box>
            <Box
              sx={{
                my: 2,
                bgcolor: "#fff",
                display: "flex",
                flexDirection: "column",
                boxSizing: "border-box",
                p: "1rem 2rem",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                <WalletIcon sx={{ fontSize: "2.7rem" }} />
                <Typography sx={{ fontSize: "1.6rem", fontWeight: "400" }}>
                  Mode of Payment
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  ml: "0.5rem",
                }}
              >
                <RadioGroup value={selectedMethod} onChange={handleChange}>
                  <FormControlLabel
                    value="COD"
                    control={
                      <Radio
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: "2.5rem",
                          },
                        }}
                      />
                    }
                    label={
                      <Typography
                        sx={{ fontSize: "1.5rem", fontWeight: "400" }}
                      >
                        Cash on delivery
                      </Typography>
                    }
                    defaultChecked
                  />
                  {admin?.isDeliveryAppOnlinePaymentAvilable && (
                    <FormControlLabel
                      value="UPI"
                      control={
                        <Radio
                          sx={{
                            "& .MuiSvgIcon-root": {
                              fontSize: "2.5rem",
                            },
                          }}
                        />
                      }
                      label={
                        <Typography
                          sx={{ fontSize: "1.5rem", fontWeight: "400" }}
                        >
                          Online Payment
                        </Typography>
                      }
                    />
                  )}
                </RadioGroup>
              </Box>
            </Box>
            {/*  */}
            <Accordion elevation={0} sx={{ "&:before": { height: "0px" } }}>
              <AccordionSummary
                expandIcon={
                  <KeyboardArrowDownIcon sx={{ fontSize: "2.5rem" }} />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <ShortTextIcon sx={{ fontSize: "3rem" }} />
                &nbsp;&nbsp;&nbsp;&nbsp;
                <Typography sx={{ fontSize: "1.6rem", fontWeight: "400" }}>
                  Add Instruction
                </Typography>
              </AccordionSummary>

              <AccordionDetails>
                <TextField
                  placeholder="Add your notes here"
                  value={ourInstruction}
                  sx={{ width: "100%" }}
                  multiline
                  rows={4}
                  inputProps={{ style: { fontSize: "1.7rem" } }}
                  onChange={
                    (e) =>
                      dispatch({
                        type: "INSTRUCTION",
                        payload: e.target.value,
                      })

                    // setNote(e.target.value)
                  }
                />
              </AccordionDetails>
            </Accordion>
            {/*  */}
            <Link to="/giftinginfo" style={{ textDecoration: "none" }}>
              <Box
                sx={{
                  my: 2,
                  bgcolor: "#fff",
                  display: "flex",
                  gap: "10px",
                  boxSizing: "border-box",
                  p: "1rem 2rem",
                  position: "relative",
                  color: "#000",
                }}
              >
                <RedeemIcon sx={{ fontSize: "2.5rem", color: "#000" }} />
                <Typography
                  sx={{
                    fontSize: "1.6rem",
                    fontWeight: "400",
                    color: "#000",
                  }}
                >
                  Order for someone else
                </Typography>
                <KeyboardArrowRightIcon
                  sx={{
                    position: "absolute",
                    right: 10,
                    fontSize: "2.5rem",
                    color: "#000",
                  }}
                />
              </Box>
            </Link>
            <Box
              sx={{
                mt: 2,
                bgcolor: "#fff",
                display: "flex",
                flexDirection: "column",
                gap: "5px",
                boxSizing: "border-box",
                p: "1rem 2rem",
              }}
            >
              <Typography sx={{ fontSize: "1.6rem", fontWeight: "400" }}>
                Order Details ({ourcart.length} Item)
              </Typography>
              <Divider sx={{ mb: 1 }} />
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography sx={{ fontSize: "1.5rem", fontWeight: "500" }}>
                  Cart Total
                </Typography>
                {admin?.currencySymbol === 'AED' ? (
                <Typography sx={{ fontSize: "1.7rem", fontWeight: "400" }}>
                  {subTotal.toFixed(2)}
                  <span style={{ fontSize: "1.4rem" }}>
                  &nbsp;{admin?.currencySymbol}
                  </span>
                </Typography>
                ):(
                  <Typography sx={{ fontSize: "1.7rem", fontWeight: "400" }}>
                  <span style={{ fontSize: "1.4rem" }}>
                    {admin?.currencySymbol}&nbsp;
                  </span>
                  {subTotal.toFixed(2)}
                </Typography>
                )}
              </Box>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography sx={{ fontSize: "1.7rem", fontWeight: "400" }}>
                  Coupon
                </Typography>
                <Typography
                  sx={{
                    fontSize: "1.7rem",
                    fontWeight: "500",
                    color: "red",
                    cursor: "pointer",
                  }}
                  onClick={couponNotAvilableModalHandleOpen}
                >
                  Apply Coupon
                </Typography>
              </Box>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography sx={{ fontSize: "1.7rem", fontWeight: "400" }}>
                  Delivery Charges
                </Typography>
                <Typography
                  sx={{ fontSize: "1.7rem", fontWeight: "400", color: "red" }}
                >
                  {address?.distance > admin.deliveryRadius ? (
                    "N/A"
                  ) : deliveryCharge === 0 ? (
                    "Free"
                  ) : (
                    <span style={{ fontSize: "1.4rem" }}>
                      {admin?.currencySymbol}&nbsp;{deliveryCharge}
                    </span>
                  )}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography sx={{ fontSize: "1.7rem", fontWeight: "400" }}>
                  {admin?.gstOrVat}
                </Typography>
                {admin?.currencySymbol === "AED" ? (
                  <Typography sx={{ fontSize: "1.7rem", fontWeight: "400" }}>
                    {gst.toFixed(2)}
                    <span style={{ fontSize: "1.4rem" }}>
                      &nbsp;{admin?.currencySymbol}
                    </span>
                  </Typography>
                ) : (
                  <Typography sx={{ fontSize: "1.7rem", fontWeight: "400" }}>
                    <span style={{ fontSize: "1.4rem" }}>
                      {admin?.currencySymbol}&nbsp;
                    </span>
                    {gst.toFixed(2)}
                  </Typography>
                )}
              </Box>
              <Divider sx={{ mb: 1 }} />
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography sx={{ fontSize: "1.7rem", fontWeight: "400" }}>
                  Total Amount
                </Typography>
                {admin?.currencySymbol === "AED" ? (
                  <Typography sx={{ fontSize: "1.7rem", fontWeight: "400" }}>
                    {(subTotal + gst + deliveryCharge).toFixed(2)}
                    <span style={{ fontSize: "1.6rem" }}>
                      &nbsp;{admin?.currencySymbol}
                    </span>
                  </Typography>
                ) : (
                  <Typography sx={{ fontSize: "1.7rem", fontWeight: "400" }}>
                    <span style={{ fontSize: "1.6rem" }}>
                      {admin?.currencySymbol}&nbsp;
                    </span>
                    {(subTotal + gst + deliveryCharge).toFixed(2)}
                  </Typography>
                )}
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              position: "absolute",
              bottom: 0,
              bgcolor: "#fff",
              height: "11%",
              // height: "10%",
              width: "100%",
              boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.15)",
              borderTopLeftRadius: "10px",
              borderTopRightRadius: "10px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              {ourcart.length <= 9 ? (
                <Typography sx={{ fontSize: "1.6rem", fontWeight: "500" }}>
                  0{ourcart.length}
                </Typography>
              ) : (
                <Typography sx={{ fontSize: "1.6rem", fontWeight: "500" }}>
                  {ourcart.length}
                </Typography>
              )}
              <Typography sx={{ fontSize: "1.6rem", fontWeight: "500" }}>
                ITEMS
              </Typography>
            </Box>

            {isLoading ? (
              <Button
                sx={{
                  backgroundColor: "#fff",
                  color: "#FF2E2E",
                  width: "150px",
                  height: "40px",
                  fontSize: "1.1rem",
                  borderRadius: "10px",
                  "&:hover": {
                    backgroundColor: "#FF2E2E",
                  },
                }}
              >
                <CircularProgress color="inherit" size={25} />
              </Button>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  gap: "20px",
                  alignItems: "center",
                  backgroundColor: "#FF2E2E",
                  color: "#fff",
                  // width: "280px",
                  // height: "40px",
                  paddingY: "6px",
                  paddingX: "10px",
                  fontSize: "1.1rem",
                  borderRadius: "10px",
                  "&:hover": {
                    backgroundColor: "#FF2E2E",
                  },
                }}
                onClick={() => {
                  user ? placeOrderFunction() : openDrawerLogin();
                }}
              >
                <Box>
                  <Typography
                    sx={{
                      color: "#fff",
                      fontSize: "1.2rem",
                      fontWeight: "400",
                    }}
                  >
                    Total
                  </Typography>
                  {admin?.currencySymbol === "AED" ? (
                    <Typography sx={{ fontSize: "2rem", fontWeight: "400" }}>
                      {(subTotal + gst + deliveryCharge).toFixed(2)}
                      <span style={{ fontSize: "1.6rem" }}>
                        &nbsp;{admin?.currencySymbol}
                      </span>
                    </Typography>
                  ) : (
                    <Typography sx={{ fontSize: "2rem", fontWeight: "400" }}>
                      <span style={{ fontSize: "1.6rem" }}>
                        {admin?.currencySymbol}&nbsp;
                      </span>
                      {(subTotal + gst + deliveryCharge).toFixed(2)}
                    </Typography>
                  )}
                </Box>

                <Typography sx={{ fontWeight: "400", fontSize: "1.6rem" }}>
                  Place Order
                </Typography>
                <KeyboardDoubleArrowRightIcon
                  sx={{
                    fontSize: "2.3rem",
                    color: "#fff",
                    ml: "-20px",
                  }}
                />
              </Box>
            )}
          </Box>
        </>
      )}
      <Box>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <MinOrderValueModal
            admin={admin}
            subTotal={subTotal}
            handleClose={handleClose}
          />
        </Modal>
      </Box>
      <Box>
        <Modal
          open={addrModalOpen}
          onClose={addrModalhandleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <AddressModal addrModalhandleClose={addrModalhandleClose} />
        </Modal>
      </Box>
      {/*  */}
      <Box>
        <Modal
          open={pickupModalOpen}
          onClose={pickupModalhandleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <PickupModal
            pickupModalhandleClose={pickupModalhandleClose}
            setTypeofOrder={setTypeofOrder}
          />
        </Modal>
      </Box>
      {/*  */}
      <Box>
        <Modal
          open={locModalOpen}
          onClose={locModalHandleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <NotDesiredLocationModal
            locModalHandleClose={locModalHandleClose}
            deliveryRadius={admin?.deliveryRadius}
          />
        </Modal>
      </Box>
      <Box>
        <Modal
          open={couponNotAvilableModalOpen}
          onClose={couponNotAvilableModalHandleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <CouponNotAvilable close={couponNotAvilableModalHandleClose} />
        </Modal>
      </Box>
      <Modal
        open={orderModalOpen}
        // onClose={orderModalHandleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <OrderPlacedSuccessModal />
      </Modal>
      <Modal
        open={removeItemModalOpen}
        onClose={removeItemModalhandleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <RemoveItem
          removeItemModalhandleClose={removeItemModalhandleClose}
          handleDeleteFavProduct={handleRemoveFromCart}
          head1={"product"}
        />
      </Modal>
      <Drawer
        duration={250}
        hideScrollbars={true}
        onClose={closeDrawerLogin}
        isVisible={isVisibleLogin}
      >
        <LoginPromptDrawer
          returnSataus={returnSataus}
          closeprevious={closeDrawerLogin}
        />
      </Drawer>
    </Box>
  );
}
