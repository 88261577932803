import {
  Box,
  Typography,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import AdjustOutlinedIcon from "@mui/icons-material/AdjustOutlined";
import { useSelector } from "react-redux";
import Moment from "react-moment";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import EditIcon from "@mui/icons-material/Edit";
import { Link } from "react-router-dom";

export default function OrderCard({ item, cancelOrderFunc, setCancelItemId }) {
  const { admin } = useSelector((state) => state);

  return (
    <Box
      sx={{
        background: "#FFFF",
        p: "10px",
        display: "flex",
        flexDirection: "column",
        gap: "3px",
        borderRadius: "5px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Accordion
          elevation={0}
          disableGutters={true}
          sx={{ "&:before": { height: "0px" }, width: "100%" }}
        >
          <AccordionSummary
            // expandIcon={
            //   <KeyboardArrowDownIcon sx={{ fontSize: "2.5rem", mr: "9rem" }} />
            // }
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={{ position: "relative" }}
          >
            <Typography
              sx={{
                fontFamily: "Roboto",
                fontSize: "1.7rem",
                fontWeight: "400",
              }}
            >
              Order #{item?.orderNo}
            </Typography>
            <KeyboardArrowDownIcon sx={{ fontSize: "2.5rem", mr: "9rem" }} />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
                position: "absolute",
                right: 0,
              }}
            >
              <AdjustOutlinedIcon
                sx={{ color: "#2D8CE3", height: "20px", width: "20px" }}
              />
              <Box
                sx={{
                  px: "10px",
                  py: "5px",
                  border: "1px solid #2D8CE3",
                  borderRadius: "5px",
                  color: "#2D8CE3",
                  background: "#2D8CE333",
                }}
              >
                <Typography sx={{ fontSize: "1.1rem" }}>
                  {item.orderStatus}
                </Typography>
              </Box>
            </Box>
          </AccordionSummary>

          <AccordionDetails>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                pl: "10px",
                pr: "10px",
                borderBottom: "1px dotted #C0C0C0	",
              }}
            >
              {item.products.map((row, index) => (
                <Box key={row._id}>
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Box sx={{ display: "flex", gap: "5px" }}>
                      <Typography sx={{ fontSize: "1.2rem" }}>
                        {index + 1}.
                      </Typography>
                      <Typography
                        sx={{ fontSize: "1.2rem", fontFamily: "Roboto" }}
                      >
                        {row.varientId.productName
                          .toLowerCase()
                          .replace(/\b\w/g, (c) => c.toUpperCase())}
                        {/* {row.productId.title} */} x {row.quantity}
                      </Typography>
                    </Box>
                    <Typography sx={{ fontSize: "1.2rem" }}>
                      <span style={{ fontSize: "1.1rem" }}>
                        {admin?.currencySymbol}&nbsp;
                      </span>
                      {(row.salePrice * row.quantity).toFixed(2)}
                    </Typography>
                  </Box>
                  {row?.addOns?.map((addon) => (
                    <Box
                      key={addon?._id}
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Box sx={{ display: "flex", gap: "5px" }}>
                        <Typography sx={{ fontSize: "1.2rem" }}>
                          &nbsp;&nbsp;&nbsp;
                        </Typography>
                        <Typography sx={{ fontSize: "1.2rem" }}>
                          {addon.name} x {addon.quantity}
                        </Typography>
                      </Box>
                      <Typography sx={{ fontSize: "1.2rem" }}>
                        <span style={{ fontSize: "1.1rem" }}>
                          {admin?.currencySymbol}&nbsp;
                        </span>
                        {addon.price}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              ))}
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                px: "10px",
              }}
            >
              <Typography sx={{ fontSize: "1.4rem", color: "#999999" }}>
                Delivery Charge
              </Typography>
              <Typography sx={{ fontSize: "1.4rem", fontWeight: "bold" }}>
                <span style={{ fontSize: "1.2rem" }}>
                  {admin?.currencySymbol}&nbsp;
                </span>
                {item.deliveryCharge.toFixed(2)}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                px: "10px",
                borderBottom: "1px dotted #C0C0C0	",
              }}
            >
              <Typography sx={{ fontSize: "1.4rem", color: "#999999" }}>
                {admin?.gstOrVat}
              </Typography>
              <Typography sx={{ fontSize: "1.4rem", fontWeight: "bold" }}>
                <span style={{ fontSize: "1.2rem" }}>
                  {admin?.currencySymbol}&nbsp;
                </span>
                {item.gstAmount.toFixed(2)}
              </Typography>
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>
      {/* <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          py: "10px",
          alignItems: "center",
          // borderBottom: "1px dotted #C0C0C0	",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
          <Box sx={{ height: "55px", width: "55px" }}>
            <img
              src={admin.logo}
              alt="logo"
              style={{ width: "100%", height: "100%" }}
            />
          </Box>
          <Box>
            <Typography sx={{ fontSize: "1.5rem", fontWeight: "500" }}>
              {admin.businessName}
            </Typography>
            <Typography sx={{ fontSize: "1.1rem", fontWeight: "400" }}>
              {admin.city}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
          <AdjustOutlinedIcon
            sx={{ color: "#2D8CE3", height: "20px", width: "20px" }}
          />
          <Box
            sx={{
              px: "10px",
              py: "5px",
              border: "1px solid #2D8CE3",
              borderRadius: "5px",
              color: "#2D8CE3",
              background: "#2D8CE333",
            }}
          >
            <Typography sx={{ fontSize: "1.1rem" }}>
              {item.orderStatus}
            </Typography>
          </Box>
        </Box>
      </Box> */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          pl: "10px",
        }}
      >
        <Typography sx={{ fontSize: "1.4rem", color: "#999999" }}>
          <Moment format="D MMM YY - h:mm a" withTitle>
            {item?.createdAt}
          </Moment>
        </Typography>
        <Typography sx={{ fontSize: "1.4rem", fontWeight: "bold" }}>
          <span style={{ fontSize: "1.3rem" }}>
            {admin?.currencySymbol}&nbsp;
          </span>
          {item.grandTotal.toFixed(2)}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          pl: "10px",
          alignItems: "center",
        }}
      >
        <Typography sx={{ fontSize: "1.4rem", color: "#53CA6C" }}>
          Payment mode {item?.paymentMethod?.toLowerCase()}
        </Typography>
        <Box sx={{ display: "flex", gap: "5px" }}>
          {item?.orderStatus !== "Delivered" &&
            item?.orderStatus !== "Cancelled" && (
              <Link
                to="/"
                variant="text"
                style={{
                  textTransform: "none",
                  fontSize: "1.2rem",
                  border: "1px solid #000",
                  borderRadius: "5px",
                  paddingLeft: "5px",
                  paddingRight: "5px",
                  paddingTop: "3px",
                  paddingBottom: "3px",
                  fontFamily: "Roboto",
                  fontSize: "1.2rem",
                  display: "flex",
                  gap: "1px",
                  alignItems: "center",
                  cursor: "pointer",
                  textDecoration: "none",
                  color: "#000",
                }}
              >
                <EditIcon />
                Edit
              </Link>
            )}
          {item.orderStatus === "Pending" && (
            <Box
              variant="text"
              sx={{
                textTransform: "none",
                fontSize: "1.2rem",
                border: "1px solid #000",
                borderRadius: "5px",
                paddingX: "5px",
                paddingY: "3px",
                fontFamily: "Roboto",
                fontSize: "1.2rem",
                display: "flex",
                gap: "1px",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => {
                setCancelItemId(item._id);
                cancelOrderFunc();
              }}
            >
              <HighlightOffIcon />
              Cancel
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
}
